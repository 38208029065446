/* #cameraPublisherContainer{
  position:absolute;
  bottom:20px;
  right:20px;
  height: 160px;
  width: 240px;
  border:1px solid #e3f825;
  z-index: 50
} */

.Controls{
  display: flex;
  position: absolute !important;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 51;
}

.igesia-logo-video{
  position: fixed;
  top: 5rem;
  left: 2rem;
  z-index:50;
}

.left-controls{
  position:fixed;
  bottom: 15px;
  left: 20px
}

.right-controls{
  position:absolute;
  bottom: 15px;
  right: 40px
}

.cursor-pointer{
  cursor: pointer;
}

.on-video-notification{
  display: inline-flex;
  background-color: #a7f0fd;
  position: fixed;
  bottom: 0px;
  opacity: 0;
  left: 15px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: bold;
  animation: on-video-notification-animation 1s forwards;
  z-index: 1000;
}

@keyframes on-video-notification-animation{
  from {bottom: 0px; opacity: 0;}
  to {bottom: 75px; opacity: 1 }
}