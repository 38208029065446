.App-main {
  position: relative;
  width: 100%;
  height: 100%;
}

.App-video-container:hover .App-control-container{
  display: flex;
}

.App-control-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  bottom: 10px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  z-index: 500;
  display: none;
}

.App-control-container.hidden {
  display: none;
}

.App-control-container .ots-video-control {
  width: 50px;
  height: 50px;
  margin: 20px 15px !important;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-color: #1c1d1f;
  background-repeat: no-repeat;
  cursor: pointer;
}

.App-control-container .ots-video-control.audio {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

/* .App-control-container .ots-video-control.audio:hover, */
.App-control-container .ots-video-control.audio.muted {
  background-color: #f80000;
  background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.App-control-container .ots-video-control.video {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.App-control-container .ots-video-control.video.muted {
    
  background-color: #f80000;
  background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.App-control-container .ots-video-control.end-call {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
  background-color: red;
}

.App-video-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.App-mask {
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  background: #252525;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-mask .react-spinner {
  position: absolute;
}

.App-mask .message {
  font-weight: 200;
}

.App-mask .message.with-spinner {
  position: absolute;
  top: 55%;
}

.App-mask .message.button {
  border: 1px solid white;
  padding: 20px 40px;
  border-radius: 6px;
  background-color: Transparent;
  color: white;
  font-family: sans-serif;
  font-size: medium;
}

.App-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
  background-image: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.App-video-container .video-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.App-video-container .video-container.small {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 175px;
  height: 110px;
  border: 2px solid #fcba00;
  border-radius: 3px;
  z-index: 2;
  display: block;
}

.App-video-container .video-container.small.left {
  left: 20px;
  border: 1px solid #00fcc2;
}

.App-video-container .video-container.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.App-video-container .video-container.active-gt2.hidden {
  display: none;
}

.App-video-container .video-container.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
  
}

 .App-video-container .four-tile.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 25% !important;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
  
}

 .App-video-container .five-tile.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 20% !important;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
  
}

 .App-video-container .three-tile.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 33% !important;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
  
}

.OT_subscriber {
  border: 2px solid #000;
  border-radius: 0px;
  margin-bottom: 5px;
}
.App-video-container
  .video-container.active-gt2.active-odd
  .OT_subscriber:first-child {
  flex-basis: 100%;
}
.OT_fit-mode-cover .OT_video-element{
    object-fit: contain;
}
.OT_publisher .OT_edge-bar-item.OT_mode-off, .OT_subscriber .OT_edge-bar-item.OT_mode-off {
  display: block;
  opacity: 1;
  top: 0;
}

.OT_mini .OT_name.OT_mode-off, .OT_mini .OT_name.OT_mode-on, .OT_mini .OT_name.OT_mode-auto, .OT_mini:hover .OT_name.OT_mode-auto{
  display: block
}

.OT_widget-container {
  background-color: #0d1215;
}

.OT_mini .OT_mute.OT_mode-auto, .OT_publisher .OT_mute.OT_mode-mini-auto, .OT_subscriber .OT_mute.OT_mode-mini-auto{
  display: none;
}

.App-video-container .video-container.small{
  max-width: 100%;
  overflow-y: auto;
}

.App-video-container .video-container.small{
  max-height: 70%;
  height: auto;
  overflow-x: hidden;
  padding: 0 8px;
  overflow-y: auto;
}

/* width */
.App-video-container .video-container.small::-webkit-scrollbar {
  width: 6px;
  border-radius:5px;
}

/* Track */
.App-video-container .video-container.small::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius:5px;
}

/* Handle */
.App-video-container .video-container.small::-webkit-scrollbar-thumb {
  background: #585858;
  border-radius:5px;
}

.App-video-container .video-container.small> .OT_subscriber{
height: 100px !important;
}



#cameraPublisherContainer.video-container.small {
  height: 100px !important;
}

#screenSubscriberContainer .OT_mute.OT_active{
  display: none;
}