.PatientDetailsDrawer {
  width: 400px;
}

.MuiPaper-root{
    background-color: #18191a !important;
}

.font-13{
    font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.select2-dropdown {
  z-index:99999;
}
