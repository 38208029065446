.create-note {
  position: absolute;
  bottom: -210px;
  right: 10px;
  height: 210px;
  width: 320px;
  z-index: 100;
  background-image: linear-gradient(
    to bottom,
    #242426,
    #2c2c30,
    #3c3c3d
  );
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 10px;
  text-align: left;
  color: aliceblue;
}

.create-note-show {
  animation: show 0.5s forwards;
}

@keyframes show {
  from {
    bottom: -210px;
  }
  to {
    bottom: 0;
  }
}

.create-note-hide {
  animation: hide 0.5s forwards;
}

@keyframes hide {
  from {
    bottom: 0px;
  }
  to {
    bottom: -200;
  }
}

.create-note-selector{
  display: inline-flex;
  height: 100%;
  width:100%;
  justify-content: center;
  align-items: center;
}

.create-note-logo {
  background-color: #181819;
  height: 125px;
  width: 105px;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  cursor: pointer;
  margin-bottom: 24px;
  font-size: 12px;
}

.create-note-hide-button{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform:translateX(-50%);
  font-size: 30px;
  padding: 1px 20px;
  cursor: pointer;
}

.add-notebook-name{
 margin: 15px;
 width: auto;
 height: auto;
}

.add-notebook-name-cancel{
  background-color:#181818;
  padding: 5px 8px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  color: #c0c0c0;
}
.add-notebook-name-save{
  
  background-color:#386ed8;
  padding: 3px 10px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  color: #fff;
}

.add-notebook-name-input{
  background-color:#1f1f1f;
  border: none;
  font-size: 13px;
  padding: 6px 10px;
  width: 220px;
  border-radius: 5px;
  border-bottom: 1px solid #386ed8;
  color: #fff;
}

.add-notebook-name-input:focus{
  outline:none;
}

.color-selector{
  background-color:#1f1f1f;
  height: 20px;
  width: 25px;
  display:inline-block;
  margin: auto 5px;
  margin-top: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.color-selector.first{
  background-color:#ff4c50;
}
.color-selector.second{
  background-color:#4c70ff;
}
.color-selector.third{
  background-color:#ffc34c;
}
.color-selector.fourth{
  background-color:#2fcda9;
}

.Notebook-selected-color{
  transform: scale(1.2);
  border: .5px solid #fff;
}