.casesheet-modal {
  position: absolute;
  background-color: #e4f9fd;
  z-index: 555;
  height: 550px;
  width: 842px;
  margin: 0px;
}

.casesheet-header{
  background-color: #3f3f3f;
  color: white;
  height: 40px;
  padding: 5px;
  padding-right: 15px;
}

.casesheet-body {
  height: 510px;
  /* overflow-y: auto; */
}

.cp{
  cursor: pointer;
}