.GridViewNote {
  width: 95%;
  /* height: auto; */
  display: inline-block;
  background-color: #181819;
  /* border-radius: 10px; */
  height: 220px;
  overflow: hidden;
  position: relative;
}

.GridViewNote-pin {
  display: inline-block;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
  padding: 4px;
}

.GridViewNote-action {
  float: right;
  margin-top: 2px;
  font-size: 25px;
  line-height: 0px;
  cursor: pointer;
  margin-right: 4px;
}

.GridViewNote-title{
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

.GridViewNote-text{
  font-size: 12px;
  margin: 10px;
  color: #dddddd;
  margin-bottom: 34px;
}

.GridViewNote-empty{
  width: 100%;
  margin-top: 70px;
  text-align: center;
  font-style: italic;
  color: #555555;
  font-size: 12px;
}

.GridViewNote-label{
  position:absolute;
  bottom: 8px;
  font-size: 10px;
  left: 10px;
  background-color: #303030;
  padding: 4px 6px;
  line-height:1;
  border-radius: 4px;
  margin-top: 5px;
  display: inline-block;
}


.note-color-selector{
  background-color:#1f1f1f;
  height: 15px;
  width: 15px;
  display:inline-block;
  margin: auto 5px;
  margin-top: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.note-color-selector.first{
  background-color:#ff4c50;
}
.note-color-selector.second{
  background-color:#4c70ff;
}
.note-color-selector.third{
  background-color:#ffc34c;
}
.note-color-selector.fourth{
  background-color:#2fcda9;
}

.Note-selected-color{
  transform: scale(1.2);
  border: .5px solid #fff;
}