/* ADDING AVATAR TO ROOM & EDIT AVATAR IN PROFILE */

#add-avatar {
  position: relative;
  height: 150px;
  width: 150px;
}

#add-avatar:hover #overlay {
  opacity: 1;
}

#add-avatar #overlay {
  position: absolute;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(51, 51, 51, 0.5);
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: 75px;
}

#add-avatar .label-overlay {
  opacity: 1 !important;
}

#add-avatar #overlay div {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#add-avatar .label {
  display: block;
  width: 100%;
  height: auto;
}

#add-avatar #avatar-image {
  cursor: pointer;
}

#add-avatar > input[type="file"] {
  display: none;
}

.profile-item .action-buttons {
  opacity: 0;
}

.profile-item:hover .action-buttons {
  opacity: 1;
}

.profile-item input::placeholder {
  font-size: 14px;
}

.notice p {
  font-size: 75%;
  line-height: 1.25;
  color: #515151 !important;
}
