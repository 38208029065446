/* .NoteApp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: "Roboto", sans-serif;
} */

.NoteApp-modal {
  position: absolute;
  height: 550px;
  width: 800px;
  /* border: 1px solid white; */
  background-color: #0c0c0d;
  border-radius: 10px;
  overflow: hidden;
  z-index: 550;
}

.NoteApp-header {
  height: 42px;
  background-color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.black-strip {
  position: absolute;
  display: block;
  width: 100%;
  height: 30px;
  background-color: #0c0c0d;
  top: 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.NoteApp-body {
  margin: 10px;
  color: white;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  height: 450px;
}

.NoteApp-footer {
  position: absolute;
  bottom: 0;
  background-color: #2c2c2c;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 50%;
  padding: 2px;
  border-bottom-left-radius: 10px;
  text-align:center;
}

.NoteApp-footer-icon {
  line-height: 12px !important;
  padding: 5px !important;
  width: 55px;
  border-radius: 5px;
}

.NoteApp-footer-text {
  text-transform: uppercase;
}

.GridModeNotesView {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin: 5px;
  align-items: stretch;
}

.writeNoteButton {
  color: #fff;
  position: absolute;
  bottom: 15px;
  right: 20px;
  z-index: 10;
  background-color: #386ed8;
  text-align: center;
  padding-top: 8px;
  padding-left: 5px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 22px;
  cursor: pointer;
}

.notebooks {
  overflow-y: hidden !important;
  overflow-x: scroll !important;
  margin: 5px;
  white-space: nowrap;
}

.NoteApp-loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
}

.NoteApp-saving {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -42px;
  background-color: #0c0c0d67;
  z-index: 9999;
}

.NoteBookNameHeader {
  position: absolute;
  margin: auto 50%;
  display: inline-block;
  color: #fff;
  font-size: 14px;
}

.cp {
  cursor: pointer;
}

.font-8 {
  font-size: 8px;
}
.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

/* width */
.ck-editor__editable::-webkit-scrollbar,
.blackScrollBar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.ck-editor__editable::-webkit-scrollbar,
.blackScrollBar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.ck-editor__editable::-webkit-scrollbar,
.blackScrollBar::-webkit-scrollbar-thumb {
  background: #504f4f;
  border-radius: 5px;
}

/* Handle on hover */
.ck-editor__editable::-webkit-scrollbar,
.blackScrollBar::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}

.text-yellow {
  color: yellow;
}

.unselectable{
  -webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}