#resultBoard {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  width: 842px;
  height: 545px;
}

.scrollBar::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}
.scrollBar::-webkit-scrollbar-track {
  background: #000000; /* color of the tracking area */
}
.scrollBar::-webkit-scrollbar-thumb {
  background-color: #c7c7c7; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 2px outset #222222; /* creates padding around scroll thumb */
}

.centered-spinner{
  position: absolute;
  z-index: 600;

  height: 600px;
  width: 842px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer{
  cursor: pointer
}