/* EDIT AVATAR OF ROOM */

#add-avatar-right {
    position: relative;
    height: 150px;
    width: 150px;
}

#add-avatar-right:hover #overlay-right {
    opacity: 1;
}

#add-avatar-right #overlay-right {
    position: absolute;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(51, 51, 51, 0.5);
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 75px;
}

#add-avatar-right .label-overlay-right {
    opacity: 1 !important;
}

#add-avatar-right #overlay-right div {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#add-avatar-right .label {
    display: block;
    width: 100%;
    height: auto;
}

#add-avatar-right #avatar-image-right {
    cursor: pointer;
}

#add-avatar-right > input[type=file] {
    display: none;
}