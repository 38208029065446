.GridViewBook{
  height: 150px;
  width: 125px;
 display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #181819;
  margin: 8px;
  border-radius: 8px;
  position: relative;
}

.GridViewBook-pin{
  position: absolute;
  top: 3px;
  left: 3px;
  font-size: 12px;
  cursor: pointer;
  padding: 4px;
}

.GridViewBook-action{
position: absolute;
right: 5px;
top: 0px;
font-size: 24px;
line-height: 0px;
cursor: pointer;
}

.GridViewBook-Text{
  position: absolute;
  bottom: 10px;
  font-size: 13px;
}