.BookLogo {
  width: 55px;
  height: 75px;
  display: inline-block;
  border-radius: 10px;
  background-image: linear-gradient(to left, #242324, #444444);
}

.BookLogo-color {
  height: 75px;
  width: 8px;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.BookLogo-line {
  position: absolute;
  display: inline-block;
  margin-left: 6px;
  height: 4px;
  width: 30px;
  border-radius: 5px;
  background-color: #707070;
}

.BookLogo-line.first{
  margin-top:12px;
}
.BookLogo-line.second{
  margin-top:50px;
}
.BookLogo-line.third{
  margin-top:60px;
}
