.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}
  
.radio-toolbar label {
    border: 1px solid #444;
    background-color: #ffffff;
}
  
.radio-toolbar label:hover {
    background-color: #dfd;
}
  
.radio-toolbar input[type="radio"]:focus + label {
    border: 1px dashed #444;
}
  
.radio-toolbar input[type="radio"]:checked + label {
    background-color: #bfb;
    border-color: #4c4;
}


.task {
    /* width: 350px; */
}

.task .form-check {
    border: 1px solid #dfdfdf;
}

.task input[type=radio], .task input[type=checkbox] {
    position: absolute;
    height: 100%;
    margin: auto;
    z-index: 1;
    right: 15px;
}

.task label {
    display: block;
    position: relative;
    flex-grow: 1;
    padding: 10px;
    cursor: pointer;
    color: #777B8E;
}

.task input[type=radio]:checked ~ label, .task input[type=checkbox]:checked ~ label {
    color: #000000;
}