.write-note-bg {
  background-color: #181819;
  display: block;
  height: 600px;
}

.write-note {
  padding: 18px 25px;
  height: 480px;
  text-align: left;
}

.write-note-title {
  background-color: #181819;
  font-size: 18px;
  color: #fff;
  border: none;
  display: block;
  width: 100%;
  font-weight: 300;
  padding: 0 10px;
  margin-top: 10px;
}

.write-note-title:focus {
  outline: none;
}

.write-note-editor {
  /* filter: invert(.9); */
  margin-top: 10px;
  padding: 10px;
}

.write-note-editor .ck-editor__editable {
  height: 345px !important;
  overflow-y: auto;
}

.write-note-editor .ck.ck-toolbar {
  background-color: #0f0f0f !important;
  color: #fff !important;
  border: none !important;
}
.write-note-editor .ck.ck-reset_all,.write-note-editor 
.ck.ck-reset_all * {
  color: #dadada !important;
}

.write-note-editor .ck.ck-editor__main > .ck-editor__editable {
  background-color: #181819 !important;
}

.write-note-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.write-note-editor .ck-focused {
  border: 0.5px solid #0f0f0f !important;
  color: #d8d8d8 !important;
}

.write-note-editor .ck.ck-list {
  filter: invert(0.9) !important;
}

.write-note-editor .ck-balloon-rotator__content {
  filter: invert(0.9) !important;
}
.write-note-editor .ck.ck-toolbar>.write-note-editor .ck-toolbar__items>:not(.ck-toolbar__line-break),.write-note-editor  .ck.ck-toolbar>.ck.ck-toolbar__grouped-dropdown:hover{
  background-color:#181819 !important;
}
.write-note-editor .ck.ck-button.ck-button_with-text:hover,.write-note-editor  a.ck.ck-button.ck-button_with-text:hover,.write-note-editor .ck.ck-button:not(.ck-disabled):hover,.write-note-editor  a.ck.ck-button:not(.ck-disabled):hover{

  background-color:#181819 !important;
}
.write-note-editor .ck.ck-button.ck-on, .write-note-editor a.ck.ck-button.ck-on{
  filter: invert(1);
}

.write-note-editor .ck.ck-color-table{
  background-color: #313131 !important;
}